<template>
    <div class="minHeight" style="padding-bottom: 90px">
        <van-form>
            <div class="width-100 paddingTb bg-white flex-row justify-content-start align-items-center">
                <img width="4px" height="24px" src="@/assets/image/icon1.png" alt="" />
                <p class="fs-big fw-mid black margin-l">施工巡查反馈</p>
            </div>
            <div class="bg-white width-90 paddingLr90">
                <p class="fs-mid grey margin-bs">施工情况</p>
                <van-cell is-link :title="title" @click="show = true" />
                <van-action-sheet v-model="show" :actions="actions" cancel-text="取消" @select="onSelect" />
                <van-checkbox-group
                    v-if="title === '施工异常'"
                    v-model="result"
                    class="width-90 padding90 flex-row justify-content-start align-items-star flex-wrap"
                >
                    <van-checkbox v-for="(name, index) in checkBoxesConfig" :key="index" :name="name" shape="square" class="width-50 margin-b"
                        >{{ name }}
                    </van-checkbox>
                </van-checkbox-group>
                <p class="fs-mid grey margin-bs margin-t">上传图片</p>
                <van-uploader class="margin-b" v-model="fileList" :before-read="beforeRead" multiple />
                <p class="fs-mid grey margin-bs">其他情况</p>
                <van-field
                    class="bg-slightGrey"
                    v-model="message"
                    rows="5"
                    autosize
                    label=""
                    type="textarea"
                    maxlength="50"
                    placeholder="请输入其他情况(选填)"
                    show-word-limit
                />
                <div v-show="!isWell">
                    <p class="fs-mid grey margin-bs">后续处理</p>
                    <van-cell is-link :title="reportTarget" @click="reportShow = true" />
                    <van-action-sheet v-model="reportShow" :actions="reportActions" cancel-text="取消" @select="onReportSelect" />
                </div>
                <div v-show="!isWell">
                    <p class="fs-mid grey margin-bs">发送短信</p>
                    <van-checkbox-group v-model="smsSendTarget">
                        <van-checkbox v-for="target in smsSendTargets" :name="target.value" :key="target.value" class="margin-bs">
                            {{ target.label }}
                        </van-checkbox>
                    </van-checkbox-group>
                </div>
                <div v-show="isComplete">
                    <p class="fs-mid grey margin-bs">施工合规情况（累计一定次数将会被标记）</p>
                    <van-rate v-model="assessLevel" />
                </div>
            </div>
            <div class="width-90 padding90 bg-white flex-row justify-content-spaceBetween align-items-center van-tabbar--fixed">
                <van-button @click="$router.back()" type="info" class="margin-r" plain block color="#007AFF">返回</van-button>
                <van-button block type="info" class="margin-l" @click="onSubmit" native-type="submit">提交</van-button>
            </div>
        </van-form>
    </div>
</template>
<script>
import axios from 'axios'
import { Toast } from 'vant'
import dd from 'gdt-jsapi'
import Compressor from 'compressorjs'

export default {
    name: '',
    components: {},
    data() {
        return {
            show: false,
            actions: [
                { name: '施工正常', isWell: true, isComplete: false },
                { name: '施工异常', isWell: false, isComplete: false },
                { name: '施工完成', isWell: true, isComplete: true }
            ],
            checkBoxesConfig: new Set([
                '无安保人员',
                '安保人员不足',
                '未设置施工路段提示标志',
                '未设置改道标志或线行诱导标志',
                '未设置施工告示牌',
                '未设置隔离设施',
                '施工区域超出范围',
                '未按时开始施工',
                '未按时完成施工',
                '路口爆闪灯缺失'
            ]),
            title: '施工正常',
            isWell: true,
            isComplete: false,
            result: [],
            message: '',
            fileList: [],
            assessLevel: 5,

            reportTarget: '就地解决',
            reportTargetLevel: 0,
            reportShow: false,
            reportActions: [
                { name: '就地解决', disabled: false, level: 0 },
                { name: '上报中队', disabled: false, level: 1 }
                // { name: '上报秩序中队', disabled: false, level: 2 },
                // { name: '上报大队', disabled: false, level: 3 }
            ],
            smsSendTarget: ['sendToEnterprise'],

            submit_unit: {
                name: '',
                contact_phone: ''
            },
            master_unit: {
                name: '',
                contact_phone: ''
            },
            isRematched: false
        }
    },
    created() {
        const { id } = this.$route.query
        axios
            .get(`/api/supervision/project_detail/${id}/`)
            .then((res) => {
                this.submit_unit = res.data.submit_unit
                this.master_unit = res.data.master_unit
                this.isRematched = res.data.rematches.length > 0
            })
            .catch(() => {
                this.$dialog.alert({
                    message: '无法获取数据，请检查网络后重新打开。'
                })
            })
    },
    mounted() {},
    computed: {
        smsSendTargets() {
            const options = []
            if (this.submit_unit) {
                options.push({
                    label: `施工单位：${this.submit_unit.name}(${this.submit_unit.contact_phone})`,
                    value: 'sendToEnterprise'
                })
            }
            if (this.master_unit) {
                options.push({
                    label: `业主单位：${this.master_unit.name}(${this.master_unit.contact_phone})`,
                    value: 'sendToMaster'
                })
            }
            return options
            // return [
            //     { label: '施工单位', value: 'sendToEnterprise' },
            //     { label: '业主单位', value: 'sendToMaster' }
            // ]
        }
    },
    methods: {
        onSelect(item) {
            this.show = false
            this.title = item.name
            this.isWell = item.isWell
            this.isComplete = item.isComplete
        },
        onReportSelect(item) {
            this.reportShow = false
            this.reportTarget = item.name
            this.reportTargetLevel = item.level
        },
        beforeRead(file) {
            if (['image/jpeg', 'image/png'].indexOf(file.type) === -1) {
                return Toast('请上传 jpg、png 格式图片').then(() => Promise.reject())
            } else {
                return new Promise((resolve) => {
                    new Compressor(file, {
                        convertSize: 3000000,
                        maxWidth: 1920,
                        maxHeight: 1080,
                        success: (f) => {
                            resolve(f)
                        },
                        error(err) {
                            console.error(err.message)
                        }
                    })
                })
            }
        },
        onSubmit() {
            this.$toast.loading({ message: '提交中', duration: 0, forbidClick: false })
            let lat = 0
            let lng = 0
            // lat = 120.655245 + 0.01 * (Math.random() - 0.5) * 2
            // lng = 27.778967 + 0.01 * (Math.random() - 0.5) * 2
            const authConfigComplete = JSON.parse(sessionStorage.getItem('authConfigComplete'))
            // 如果鉴权不通过则不定位
            if (process.env.NODE_ENV === 'production' && authConfigComplete) {
                dd.getGeolocation({
                    targetAccuracy: 200,
                    coordinate: 1,
                    withReGeocode: false,
                    useCache: true
                })
                    .then(
                        (res) => {
                            lat = res.latitude
                            lng = res.longitude
                            return this.doSubmit(lat, lng)
                        },
                        (err) => {
                            console.error(err)
                            return this.$dialog
                                .confirm({
                                    title: '定位失败',
                                    message: '定位失败，但仍然能上报信息。如果确定上报请联系管理员调整位置。',
                                    confirmButtonText: '确定上报',
                                    cancelButtonText: '取消上报'
                                })
                                .then(
                                    () => {
                                        return this.postSubmit(0, 0)
                                    },
                                    () => {}
                                )
                        }
                    )
                    .finally(() => {
                        this.$toast.clear()
                    })
            } else {
                this.doSubmit(lat, lng).finally(() => {
                    this.$toast.clear()
                })
            }
        },
        doSubmit(lat, lng) {
            // 如果项目还没有坐标，则要求确认目前的地点为坐标
            if (!this.isRematched) {
                // 使用高德地图将坐标转换成地址
                let geocoder = new window.AMap.Geocoder({
                    city: '330381000000'
                })
                const lnglat = [lng, lat]
                return new Promise((resolve, reject) => {
                    geocoder.getAddress(lnglat, (status, result) => {
                        if (status === 'complete' && result.info === 'OK') {
                            resolve(result)
                        } else {
                            reject(status)
                        }
                    })
                }).then(
                    (result) => {
                        return this.$dialog
                            .confirm({
                                title: '确认第一次坐标',
                                message: `这是这个施工的第一次巡查\n请确认你的位置确实是施工位置\n定位位置：${result.regeocode.formattedAddress}\n如果定位与实际位置相差很大，请联系开发或管理员手动修正`
                            })
                            .then(
                                () => {
                                    return this.postSubmit(lat, lng)
                                },
                                () => {}
                            )
                    },
                    () => {
                        return this.$dialog
                            .confirm({
                                title: '定位显示失败',
                                message: '定位显示失败，但仍然能上报信息。如果确定上报请联系管理员调整位置。\n' + `lat: ${lat}, lng: ${lng}`,
                                confirmButtonText: '确定上报',
                                cancelButtonText: '取消上报'
                            })
                            .then(
                                () => {
                                    return this.postSubmit(lat, lng)
                                },
                                () => {}
                            )
                    }
                )
            } else {
                return this.postSubmit(lat, lng)
            }
        },
        postSubmit(lat, lng) {
            let formData = new FormData()
            formData.append('lat', lat)
            formData.append('lng', lng)
            formData.append('project', this.$route.query.id)
            formData.append('work_status', this.result.join('\n'))
            formData.append('other_status', this.message)
            formData.append('report_level', this.reportTargetLevel)
            formData.append('is_well', this.isWell)
            formData.append('is_complete', this.isComplete)
            formData.append('assess_level', (5 - this.assessLevel) * 2)
            for (let file of this.fileList) {
                formData.append('images', file.file)
            }
            this.smsSendTarget.forEach((target) => {
                formData.append('sms_targets', target)
            })
            return axios
                .post('api/supervision/submit_rematch/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(
                    () => {
                        this.$router.back()
                    },
                    (err) => {
                        axios.post('/api/log/', {
                            level: 40,
                            content: {
                                err: err,
                                context: formData
                            }
                        })
                        return this.$dialog.alert({
                            title: '上传失败',
                            message: `上传失败\n请检查网络并重新尝试。\n` + err
                        })
                    }
                )
        }
    }
}
</script>

<style lang="less" scoped>
.amap-container {
    width: 200px;
    height: 200px;
}
</style>
